import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '150px',

    gap: '50px',
    [theme.breakpoints.down('lg')]: {
      height: '130px',
      rowGap: '40px',
    },
    [theme.breakpoints.down('md')]: {
      height: '110px',
      gap: '30px',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      height: '90px',
      gap: '20px',
      justifyContent: 'center',
    },
  },
}));
