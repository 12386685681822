import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  heroNFTsFrame: {
    display: 'flex',
    width: '40%',
    height: '100%',

    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  heroNFTsContainer: {
    display: 'grid',
    // width: '700px',
    // height: '700px',
    width: '35vw',
    height: '35vw',
    // gridTemplateColumns: '350px 350px',
    gridTemplateColumns: '17.5vw 17.5vw',
    '& div': {
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '300px 300px',
      width: '600px',
      height: '600px',
      paddingTop: 40,
      paddingBottom: 40,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '220px 220px',
      width: '440px',
      height: '440px',
      paddingTop: 80,
      paddingBottom: 40,
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '200px 200px',
      width: '400px',
      height: '400px',
      paddingTop: 80,
      paddingBottom: 40,
    },
  },
}));
