import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { useStyles } from './index.style';

interface Props extends CustomProps {
  opensea: React.ReactNode;
}

export const Ownership: FC<Props> = ({ children, opensea }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.contentFrame}>
        <div className={classes.contentTextContainer}>
          {opensea}
          <p className={classes.paragraph}>Find your favorites on OpenSea.</p>
          <h1 className={classes.contentText}>Perks of Ownership</h1>
          <p className={classes.paragraph}>
            The owner of a Never Ending Ticket (NET) will get a ticket to the
            annual Creator Economy Expo every year for as long as the event is
            held. Every Never Ending Ticket NFT image is unique. All NETS also
            include VIP party access at the event. Randomly minted NETs offer
            exclusive perks such as the ability to meet a keynote, get event
            swag, enjoy a meal with founders Brian Clark and Joe Pulizzi, and a
            1/1 opportunity to introduce a speaker from the main stage. These
            perks are only redeemable at the Creator Economy Expo event.
          </p>
          {children}
        </div>
      </div>
    </>
  );
};
