import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fonts, color } from 'src/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  heroTextFrame: {
    display: 'flex',
    width: '60%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingTop: 0,
    },
  },

  heroTextContainer: {
    display: 'block',
    width: '100%',
    height: '100%',
    paddingLeft: 80,
    paddingRight: 200,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 100,
      paddingLeft: 60,
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: 30,
    },
  },
  heroText: {
    display: 'relative',
    fontFamily: fonts.rubik,
    fontWeight: 600,
    color: color.background,
    fontSize: '4.5rem',

    [theme.breakpoints.down('xl')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5rem',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.6rem',
    },
  },
  heroDescription: {
    display: 'relative',
    fontFamily: fonts.rubik,
    color: color.background,
    fontSize: '1.4rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  mintedText: {
    fontFamily: fonts.rubik,
    color: color.background,
    fontSize: '1.9rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
    },
  },

  soldOutText: {
    color: color.white,
    fontFamily: fonts.rubik,
    fontSize: '1.2rem',
    marginTop: -10,
  },
}));
