import React, { FC, useEffect, useState } from 'react';
import { CustomProps } from 'src/core';
import { useStyles } from './index.style';
import { useMediaQuery, Theme } from '@mui/material';

interface Props extends CustomProps {
  active: boolean;
}

export const MobileNav: FC<Props> = ({ children, active }) => {
  const classes = useStyles();

  return (
    <>{active && <div className={classes.dropDownContainer}>{children}</div>}</>
  );
};
