import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fonts, color } from 'src/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  contentFrame: {
    display: 'flex',
    width: '100%',
  },
  contentTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 600,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down('xl')]: {
      paddingLeft: 80,
      paddingRight: 80,
      height: 650,
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 60,
      paddingRight: 60,
      height: 800,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 20,
      height: 900,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      paddingRight: 10,
      height: 1000,
    },
    backgroundColor: color.button_background,
  },
  contentText: {
    display: 'relative',
    fontFamily: fonts.rubik,
    textAlign: 'center',
    fontWeight: 500,
    color: color.white,
    fontSize: '4rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5rem',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.6rem',
    },
  },
  paragraph: {
    color: color.white,
    fontFamily: fonts.rubik,
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '32px',
    fontSize: '1.5rem',
  },
}));
