import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fonts, color } from 'src/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  contentFrame: {
    display: 'flex',
    width: '100%',
  },
  contentTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 600,
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down('xl')]: {
      height: 650,
    },
    [theme.breakpoints.down('lg')]: {
      height: 700,
    },
    [theme.breakpoints.down('sm')]: {
      height: 800,
    },
    backgroundColor: color.background,
  },
  contentText: {
    display: 'relative',
    fontFamily: fonts.rubik,
    fontWeight: 500,
    color: color.heading_text,
    fontSize: '4rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5rem',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.6rem',
    },
  },
  pricingInfo: {
    fontFamily: fonts.rubik,
    fontWeight: 400,
    textAlign: 'center',

    lineHeight: '32px',
    fontSize: '1.5rem',
  },
}));
