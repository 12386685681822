import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { Button } from '@mui/material';
import { fonts, color } from 'src/styles';

import { styled } from '@mui/material/styles';
// import { useStyles } from './index.style';

interface Props extends CustomProps {
  href?: string;
  onClick?: () => void;
}

export const MobileNavButton: FC<Props> = ({
  children,
  style,
  onClick,
  href,
}) => {
  //   const classes = useStyles();
  return (
    <>
      <StyledButton
        variant="contained"
        style={style}
        onClick={onClick}
        href={href}
      >
        {children}
      </StyledButton>
    </>
  );
};

const StyledButton = styled(Button)<Props>(({ theme }) => ({
  position: 'relative',
  backgroundColor: color.background_alt,
  fontFamily: fonts.rubik,
  fontWeight: 500,
  fontSize: '1.5rem',
  color: color.secondary,
  width: '100%',
  height: 80,
  borderRadius: 0,
  borderBottom: `1px solid ${color.black}`,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: color.background_alt,
  },
}));
