import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { Button } from '@mui/material';
import { fonts, color } from 'src/styles';

import { styled } from '@mui/material/styles';
import { useStyles } from './index.style';

interface Props extends CustomProps {
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small' | undefined;
}

export const SolidButton: FC<Props> = ({
  children,
  style,
  onClick,
  href,
  disabled,
  size = 'large',
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={
          size === 'large'
            ? classes.buttonBackground
            : classes.buttonBackgroundMd
        }
        style={
          disabled ? { filter: 'grayscale(100%)', cursor: 'not-allowed' } : {}
        }
      >
        <StyledButton
          variant="contained"
          style={style}
          onClick={onClick}
          size={size}
          disabled={disabled}
        >
          {children}
        </StyledButton>
      </div>
    </>
  );
};

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'size',
})<Props>(({ theme, size }) => ({
  ...(theme && {
    position: 'relative',
    right: '7px',
    top: '-7px',
    backgroundColor: color.primary,
    color: color.secondary,
    // transition: 'ease-in-out 0.3s',
    borderRadius: '10px',
    textTransform: 'uppercase',
    fontFamily: fonts.rubik,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: color.primary,
    },
    '&:disabled': {
      backgroundColor: color.primary,
      color: color.secondary,
    },
    ...(size === 'large' && {
      width: '200px',
      height: '50px',
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        width: '180px',
        height: '45px',
        fontSize: '0.9rem',
      },
    }),
    ...(size === 'medium' && {
      width: '100px',
      height: '36.5px',
      fontSize: '0.9rem',
    }),
  }),
}));
