import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color, fonts } from 'src/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  buttonBackground: {
    width: '200px',
    height: '50px',
    borderRadius: '10px',
    marginTop: '7px',
    backgroundColor: color.button_background,

    [theme.breakpoints.down('sm')]: {
      width: '180px',
      height: '45px',
    },
  },
  buttonBackgroundMd: {
    width: '200px',
    height: '50px',
    borderRadius: '10px',
    marginTop: '7px',
    backgroundColor: color.button_background,
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '36.5px',
    },
  },
}));
