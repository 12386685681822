import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fonts, color } from 'src/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  contentFrame: {
    display: 'flex',
    width: '100%',
  },
  contentTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    height: 1150,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down('xl')]: {
      height: 1150,
    },
    [theme.breakpoints.down('lg')]: {
      height: 1300,
    },
    [theme.breakpoints.down('md')]: {
      height: 1550,
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down('sm')]: {
      height: 1980,
      paddingLeft: 30,
      paddingRight: 30,
    },
    [theme.breakpoints.down('xs')]: {
      height: 1950,
      paddingLeft: 10,
      paddingRight: 10,
    },

    backgroundColor: color.button_background,
  },
  contentText: {
    fontFamily: fonts.rubik,
    fontWeight: 500,
    color: color.white,
    fontSize: '4rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5rem',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.6rem',
    },
  },
  subHeader: {
    fontFamily: fonts.rubik,
    color: color.white,
  },
  paragraph: {
    color: color.white,
    fontFamily: fonts.rubik,
    fontSize: '1.2rem',

    lineHeight: '26px',
  },
}));
