import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { useStyles } from './index.style';

interface Props extends CustomProps {
  src: string;
}

export const BoxImage: FC<Props> = ({ src }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.boxContainer}>
        <img className={classes.src} src={src} />
      </div>
    </>
  );
};

export default BoxImage;
