import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { Button } from '@mui/material';
import { fonts, color } from 'src/styles';

import { styled } from '@mui/material/styles';
// import { useStyles } from './index.style';

interface Props extends CustomProps {
  src: string;
  href?: string;
  onClick?: () => void;
}

export const IconButton: FC<Props> = ({
  children,
  style,
  onClick,
  href,
  src,
}) => {
  //   const classes = useStyles();
  return (
    <>
      <StyledButton
        variant="contained"
        style={style}
        onClick={onClick}
        href={href}
        src={src}
        disableRipple
      >
        {children}
      </StyledButton>
    </>
  );
};

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'src',
})<Props>(({ theme, src }) => ({
  position: 'relative',
  backgroundColor: 'transparent',
  backgroundImage: `url(${src})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'contain',
  width: 24,
  height: 24,
  boxShadow: 'none',
  '&:hover': {
    filter: 'brightness(80%)',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}));
