import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { useStyles } from './index.style';

interface Props extends CustomProps {}

export const Frame: FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.frame}>{children}</div>
    </>
  );
};
