import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { color } from 'src/styles';
import { useStyles } from './index.style';

interface Props extends CustomProps {
  Register: React.ReactNode;
  MetaMaskLink: React.ReactNode;
  CEXLink: React.ReactNode;
}

export const FAQ: FC<Props> = ({
  children,
  Register,
  MetaMaskLink,
  CEXLink,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.contentFrame}>
        <div className={classes.contentTextContainer}>
          <h1 className={classes.contentText}>FAQ</h1>
          <h2 className={classes.subHeader}>
            Q: What do I receive as an owner of a Never Ending Ticket NFT?
          </h2>
          <p className={classes.paragraph}>
            The owner of a NET will get a ticket to the annual Creator Economy
            Expo for as long as the event takes place. All NETS also include VIP
            party access and a select few offer exclusive perks such as the
            ability to meet a keynote, get event swag, enjoy a meal with
            founders Brian Clark and Joe Pulizzi, and a 1/1 opportunity to
            introduce a speaker from the main stage. These perks are only
            redeemable at CEX, the Creator Economy Expo event. After minting
            your NET, you can view your NETs access level under the Access
            Attribute MetaData on this site or on OpenSea.
          </p>
          <h2 className={classes.subHeader}>
            Q: When and where does the next CEX, Creator Economy Expo, take
            place?
          </h2>
          <p className={classes.paragraph}>
            The next Creator Economy Expo takes place May 2 - 4, 2022 at the
            Arizona Grand Resort in Phoenix, AZ. For more information visit the
            event site
          </p>
          <h2 className={classes.subHeader}>
            Q: Do I still have to register for the conference even if I own the
            NET?
          </h2>
          <p className={classes.paragraph}>
            Yes. Please {Register} on this site, verify your NET ownership and
            submit the registration form to confirm your attendance.
          </p>
          <h2 className={classes.subHeader}>
            Q: How do I create a digital wallet?
          </h2>
          <p className={classes.paragraph}>
            We recommend visiting {MetaMaskLink} for a step-by-step guide on how
            to create a crypto wallet and interact on blockchain applications.
          </p>
          <h2 className={classes.subHeader}>
            Q: About the Creator Economy Expo
          </h2>
          <p className={classes.paragraph}>
            To learn more about the Creator Economy Expo, visit the event site
            at {CEXLink}.
          </p>
          <h2 className={classes.subHeader}>Q: Contact</h2>
          <p className={classes.paragraph}>
            For questions about the event or your NET, please contact
            cex@thetilt.com.
          </p>
        </div>
      </div>
    </>
  );
};
