import React, { FC, useState } from 'react';
import { CustomProps } from 'src/core';
import { useStyles } from './index.style';

interface Props extends CustomProps {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  header?: string;
  description?: string;
  image?: React.ReactNode;
  showImage: boolean;
}

export const Modal: FC<Props> = ({
  children,
  active,
  setActive,
  header,
  description,
  image,
  showImage,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.modalBackdrop}
        style={active ? { visibility: 'visible' } : { visibility: 'hidden' }}
        onClick={() => {
          setActive(!active);
        }}
      />
      <div className={classes.modalFrame}>
        <div
          className={classes.modalContainer}
          style={{
            visibility: active ? 'visible' : 'hidden',
            display: showImage ? 'flex' : 'unset',
            alignItems: showImage ? 'center' : 'unset',
            flexDirection: showImage ? 'column' : 'unset',
          }}
        >
          <h1 className={classes.header}>{header}</h1>
          {description !== '' && (
            <p className={classes.description}>{description}</p>
          )}
          {!showImage && (
            <div className={classes.buttonContainer}>{children}</div>
          )}
          {showImage && image}
        </div>
      </div>
    </>
  );
};
