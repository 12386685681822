import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { color } from 'src/styles';
import { useStyles } from './index.style';
import { TextButton } from 'src/pages/_components';

interface Props extends CustomProps {
  minted: number | '-';
  soldout: boolean;
}

export const HeroText: FC<Props> = ({ children, minted, soldout }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.heroTextFrame}>
        <div className={classes.heroTextContainer}>
          <h1 className={classes.heroText}>
            Never Ending <br />
            Tickets
          </h1>
          <p className={classes.heroDescription}>
            CEX NETs are a set of 100 never-ending tickets for CEX, the annual
            Creator Economy Expo. Learn more about NET perks and pricing below.
          </p>
          <p className={classes.mintedText}>
            Total NETs Minted: {minted}
            <strong style={{ color: color.primary }}> / 100</strong>
          </p>
          {soldout && (
            <p className={classes.soldOutText}>
              <strong>Sold out!</strong> View the collection on{' '}
              <TextButton
                isdark="false"
                isunderlined="true"
                size={'small'}
                onClick={() => {
                  window.open(
                    'https://opensea.io/collection/cex-never-ending-tickets',
                    '_blank'
                  );
                }}
              >
                opensea.io
              </TextButton>
            </p>
          )}
          {children}
        </div>
      </div>
    </>
  );
};
