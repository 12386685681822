export type validateType = 'invalid_email' | 'success';

export const validateEmail = (email: string): validateType => {
  if (
    email.length === 0 ||
    email.length < 6 ||
    !email.includes('@') ||
    email.split('@')[0].length < 1 ||
    email.split('@')[1].length < 4 ||
    !email.split('@')[1].includes('.')
  ) {
    return 'invalid_email';
  }
  return 'success';
};
