import React, { FC, useState } from 'react';
import { CustomProps } from 'src/core';
import { useStyles } from './index.style';

interface Props extends CustomProps {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  receipt: string | null;
}

export const ModalNotification: FC<Props> = ({
  active,
  setActive,
  receipt,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.modalBackdrop}
        style={active ? { visibility: 'visible' } : { visibility: 'hidden' }}
        onClick={() => {
          setActive(!active);
        }}
      />
      <div className={classes.modalFrame}>
        <div
          className={classes.modalContainer}
          style={{
            visibility: active ? 'visible' : 'hidden',
          }}
        >
          <h1 className={classes.header}>Thank you for your purchase!</h1>
          <br />
          <br />
          <p className={classes.description}>
            Please allow some time for our systems to detect your purchase.
            Check back soon to refresh the page and register for the upcoming
            CEX event with your Never Ending Ticket NFT.
          </p>
          <br />
          <br />
          {/* {receipt !== '' && receipt !== null && (
            <div>
              {'View the status of your transaction on '}
              <a
                href={`${receipt}`}
                style={{ color: 'black' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {'etherscan'}
              </a>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};
