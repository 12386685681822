import { FC } from 'react';
import { CustomProps } from 'src/core';
import { TextField } from '@mui/material';

export interface Props extends CustomProps {
  label?: string;
  variant?: string;
  onChange?:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  size?: 'medium' | 'small';
  type?: 'text' | 'password';
  value: string;
  name: string;
  required?: boolean;
  error?: boolean;
}

export const OutlinedField: FC<Props> = ({
  children,
  label,
  onChange,
  value,
  name,
  required,
  error,
  type = 'text',
  size = 'medium',
}) => {
  return (
    <>
      <TextField
        label={label}
        variant="outlined"
        onChange={onChange}
        size={size}
        value={value}
        name={name}
        type={type}
        required={required}
        error={error}
      >
        {children}
      </TextField>
    </>
  );
};
