import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { useStyles } from './index.style';

interface Props extends CustomProps {}

export const Pricing: FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.contentFrame}>
        <div className={classes.contentTextContainer}>
          <h1 className={classes.contentText}>Pricing</h1>
          <p className={classes.pricingInfo}>
            Early minters benefit from discounted prices!
            <br />
            <br />
            First 25 NETs Sold: 0.75 ETH
            <br />
            Second 25 NETs Sold: 0.9 ETH
            <br />
            Third 25 NETs Sold: 1.05 ETH
            <br />
            Last 25 NETs Sold: 1.25 ETH
            <br />
            <br />
            There are only 100 NETs. When they are gone, they're gone!
          </p>
          {children}
        </div>
      </div>
    </>
  );
};
