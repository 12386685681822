export const color = {
  link: '#404aa0',
  primary: '#ece169',
  secondary: '#14152d',
  background: '#ffffff',
  background_alt: '#f9f9fd',
  body_text: '#3e3e55',
  heading_text: '#14152d',
  button_background: '#484F8D',
  background_mask: '#232957',
  blue: 'blue',
  black: 'black',
  white: '#ffffff',
};
