import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color, fonts } from 'src/styles';
import { background_edit } from 'src/assets';
import { opensea } from 'src/assets';

export const useStyles = makeStyles((theme: Theme) => ({
  landingPageContainer: {
    width: '100vw',
    height: '100vh',
    background: `url(${background_edit}) no-repeat center center fixed`,
    backgroundSize: 'cover',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 20,
    gap: 20,
  },
  registrationHeader: {
    fontFamily: fonts.rubik,
  },
  registrationParagraph: {
    fontFamily: fonts.rubik,
  },
  dietaryRestrictions: {
    fontFamily: fonts.rubik,
    marginTop: -10,
  },
  displayNET: {
    width: 500,
    height: 500,
    [theme.breakpoints.down('md')]: {
      width: 400,
      height: 400,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
      width: 350,
      height: 350,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
      width: 300,
      height: 300,
    },
    backgroundSize: 'contain',
  },
}));
