import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color, fonts, zindex } from 'src/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  modalBackdrop: {
    zIndex: zindex.bottom,
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgb(0,0,0,0.6)',
  },
  modalFrame: {
    zIndex: zindex.middle,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    visibility: 'hidden',
  },
  modalContainer: {
    position: 'relative',
    zIndex: zindex.top,
    width: '480px',
    height: '580px',
    borderRadius: '20px',
    backgroundColor: color.background,
    padding: 60,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 50,
      width: '400px',
      height: '585px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 30px 0px 30px',
      width: '350px',
      height: '610px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 30px 50px 30px',
      width: '280px',
      height: '550px',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    fontFamily: fonts.rubik,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.6em',
    },
  },
  description: {
    fontFamily: fonts.rubik,
    fontSize: '1.6em',
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em',
    },
  },
}));
