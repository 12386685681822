import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  frame: {
    position: 'relative',
    width: '100%',
    top: '150px',
    height: 'calc(100vh - 150px)',

    [theme.breakpoints.down('lg')]: {
      top: '130px',
      height: 'calc(100vh - 130px)',
    },
    [theme.breakpoints.down('md')]: {
      top: '110px',
      height: 'calc(100vh - 110px)',
    },
    [theme.breakpoints.down('sm')]: {
      top: '90px',
      height: 'calc(100vh - 90px)',
    },
  },
}));
