import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  heroFrame: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'scroll',
    flexWrap: 'wrap',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));
