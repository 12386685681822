import React, { FC, useEffect, useState } from 'react';
import { CustomProps } from 'src/core';
import { useStyles } from './index.style';

interface Props extends CustomProps {
  breakpoint: boolean;
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Navbar: FC<Props> = ({
  children,
  breakpoint,
  active,
  setActive,
}) => {
  const classes = useStyles();

  return breakpoint ? (
    <>
      <div className={classes.logoContainer}>
        <div
          className={classes.navLogo}
          onClick={() => {
            window.open('https://cex.events/', '_blank');
          }}
        />
      </div>
      {children}
      <div />
    </>
  ) : (
    <>
      <div className={classes.logoContainer}>
        <div className={classes.navLogo} />
      </div>
      <div
        onClick={() => {
          setActive(!active);
        }}
        className={classes.hamburgerToggle}
      >
        <div
          aria-pressed={active}
          className={active ? classes.hamburgerActive : classes.hamburgerIcon}
        />
      </div>
      <div />
    </>
  );
};
