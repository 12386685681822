import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'src/styles';
import LandingPage from './pages/landing-page';
import { MoralisProvider } from 'react-moralis';

const App: FC = () => {
  return (
    <>
      <MoralisProvider
        appId={
          process.env.REACT_APP_app_id !== undefined
            ? process.env.REACT_APP_app_id
            : ''
        }
        serverUrl={
          process.env.REACT_APP_server_url !== undefined
            ? process.env.REACT_APP_server_url
            : '/'
        }
      >
        <ThemeProvider theme={theme}>
          <LandingPage></LandingPage>
        </ThemeProvider>
      </MoralisProvider>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
