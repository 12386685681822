import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { Button, colors } from '@mui/material';
import { fonts, color } from 'src/styles';

import { styled } from '@mui/material/styles';

interface Props extends CustomProps {
  href?: string;
  onClick?: () => void;
  highlighted?: boolean;
  icon?: string;
}

export const ModalButton: FC<Props> = ({
  children,
  style,
  onClick,
  href,
  icon,
  highlighted = false,
}) => {
  return (
    <>
      <StyledButton
        variant="contained"
        style={style}
        onClick={onClick}
        href={href}
        highlighted={highlighted}
      >
        <img src={icon} style={{ position: 'absolute', left: '30px' }} />
        <div>{children}</div>
      </StyledButton>
    </>
  );
};

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'highlighted',
})<Props>(({ theme, highlighted }) => ({
  ...(highlighted && {
    color: color.white,
    backgroundColor: color.blue,
    fontFamily: fonts.rubik,
    fontWeight: 400,
    fontSize: '1.4rem',
    textTransform: 'none',
    textDecoration: 'none',
    borderRadius: 60,

    width: '100%',
    height: 60,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: color.blue,
      filter: 'brightness(80%)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  }),
  ...(!highlighted && {
    color: color.black,
    backgroundColor: color.white,
    fontFamily: fonts.rubik,
    fontWeight: 400,
    fontSize: '1.4rem',
    textTransform: 'none',
    textDecoration: 'none',
    borderRadius: 60,

    width: '100%',
    height: 60,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: color.white,
      filter: 'brightness(80%)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  }),
}));
