import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color, zindex } from 'src/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  dropDownContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: zindex.middle,

    top: 110,
    [theme.breakpoints.down('sm')]: {
      top: 90,
    },
  },
}));
