import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cexLogo } from 'src/assets';
import { color } from 'src/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes toggleBefore': {
    '0%': {},
    '50%': {
      transform: 'translateY(-15px)',

      '100%': {
        transform: 'rotate(-45deg)',
      },
    },
  },
  navLogo: {
    width: '100px',
    height: '40px',
    backgroundImage: `url(${cexLogo})`,
    backgroundSize: 'contain',
    transitionDelay: '1s',

    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(80%)',
    },
  },
  logoContainer: {
    paddingLeft: '50px',
    flexGrow: '1',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '40px',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '20px',
    },
  },
  hamburgerActive: {
    transitionDelay: '.2s',
    position: 'relative',
    display: 'block',
    width: 32,
    height: 2,
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: 'transparent',

    '&:after': {
      transition: 'top ease-in-out .2s, transform ease-in-out .2s .2s',
      content: '""',
      backgroundColor: color.background,
      top: '0px',
      height: '2px',
      width: '100%',
      position: 'absolute',
      transform: 'rotate(45deg)',
    },
    '&:before': {
      transition: 'top ease-in-out .2s, transform ease-in-out .2s .2s',
      content: '""',
      backgroundColor: color.background,
      top: '0px',
      height: '2px',
      width: '100%',
      position: 'absolute',
      transform: 'rotate(-45deg)',
    },
  },
  hamburgerIcon: {
    transitionDelay: '.2s',
    position: 'relative',
    display: 'block',
    width: 32,
    height: 2,
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: color.background,

    '&:after': {
      transition: 'top ease-in-out .2s .2s, transform ease-in-out .2s',
      content: '""',
      backgroundColor: color.background,
      top: '10px',
      height: '2px',
      width: '100%',
      position: 'absolute',
      transform: 'rotate(0deg)',
    },
    '&:before': {
      transition: 'top ease-in-out .2s .2s, transform ease-in-out .2s',
      content: '""',
      backgroundColor: color.background,
      top: '-10px',
      height: '2px',
      width: '100%',
      position: 'absolute',
    },
  },
  hamburgerToggle: {
    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(80%)',
    },
  },
}));
