import React, { FC, useEffect, useState } from 'react';
import {
  SolidButton,
  TextButton,
  Header,
  Frame,
  BoxImage,
  Navbar,
  Modal,
  ModalButton,
  OutlinedField,
  IconButton,
  WideImageButton,
  ModalNotification,
} from 'src/pages/_components';
import {
  FAQ,
  HeroFrame,
  HeroNFTs,
  HeroText,
  Ownership,
  Pricing,
} from './_components';
import { useStyles } from './index.style';
import {
  metamask,
  NFT0_0,
  NFT0_1,
  NFT1_0,
  NFT1_1,
  wallet_connect,
  discord,
  opensea,
} from 'src/assets';
import {
  useMediaQuery,
  Theme,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { MobileNav } from '../_components/Screen/MobileNav';
import { MobileNavButton } from '../_components/Buttons/MobileNavButton';
import {
  useMoralis,
  useMoralisQuery,
  useNewMoralisObject,
} from 'react-moralis';
import { validateEmail } from './util/validateEmail';
import { abi, FormI, ModalType, DietaryTypes } from 'src/core';
import Moralis from 'moralis';
import { AbiItem } from 'web3-utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const ImageQuery = async (token_id: string) => {
  const NETs = Moralis.Object.extend('NETs');
  const query = new Moralis.Query(NETs);
  query.equalTo('token_id', token_id);
  return await query.find();
};

const GetUserBalance = async (user: Moralis.User<Moralis.Attributes>) => {
  return await Moralis.Cloud.run('GetBalance', {
    userAddress: user.get('ethAddress'),
  });
};

const RegistrationQuery = async (user: Moralis.User<Moralis.Attributes>) => {
  const SignUps = Moralis.Object.extend('Signups');
  const query = new Moralis.Query(SignUps);
  query.equalTo('ethAddress', user.get('ethAddress'));
  try {
    return await query.find();
  } catch (err) {
    return null;
  }
};
const MintNET = async (
  user: Moralis.User<Moralis.Attributes>,
  web3: Moralis.Web3,
  abi: AbiItem[]
) => {
  // const web3 = new Web3(web3.currentProvider);
  const contractAddress = '0x4008f13addd10f08b66f0d8e88849c3f34fed0af'; // address
  const contractInstance = new web3.eth.Contract(abi, contractAddress, {
    from: user.get('ethAddress'),
  }); // abi sent below

  const query = new Moralis.Query('Purchases');
  const result = await query.find();
  let price;

  if (result.length >= 0) {
    if (result.length < 25) {
      price = Moralis.Units.ETH(0.75);
    } else if (result.length < 50) {
      price = Moralis.Units.ETH(0.9);
    } else if (result.length < 75) {
      price = Moralis.Units.ETH(1.05);
    } else {
      price = Moralis.Units.ETH(1.25);
    }
  }

  const log: string = await contractInstance.methods
    .buy()
    .send(
      {
        from: user.get('ethAddress'),
        value: price,
        gas: 176610,
        gasPrice: await await web3.eth.getGasPrice(),
      },
      (err: Error | null, receipt: string) => {
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
          return '';
        }

        localStorage.setItem(`${user.get('ethAddress')}-purchaseFlag`, 'true');

        toast.success(
          <div>
            {'Success! View the status of your transaction on '}
            <a
              href={`https://rinkeby.etherscan.io/tx/${receipt}`}
              style={{ color: 'black' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {'etherscan'}
            </a>
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          }
        );
        return `https://rinkeby.etherscan.io/tx/${receipt}`;
      }
    );
  return log;
};

const LandingPage: FC = () => {
  const myNETModal = () => {
    setModalContent('my_net');
    setModalActive(!modalActive);
  };

  const {
    Moralis,
    isInitialized,
    authenticate,
    isAuthenticated,
    user,
    web3,
    enableWeb3,
    isWeb3Enabled,
  } = useMoralis();

  useEffect(() => {
    if (!isWeb3Enabled || !web3) {
      enableWeb3();
    }
  }, [isWeb3Enabled]);

  // Get original purchase data (amount will determine price passed in to above function)
  const {
    data: mintingData,
    error: mintingDataError,
    isLoading: mintingDataIsLoading,
  } = useMoralisQuery('Purchases', query => query.limit(100), [100], {
    live: true,
  });

  const [successfulRegister, setSuccessfulRegister] = useState<boolean>(false);
  // Save registration info to moralis table "Signups"
  const {
    isSaving,
    error: saveError,
    save: executeSave,
  } = useNewMoralisObject('Signups');

  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  // Get user registration data
  const [registrationData, setRegistrationData] = useState<
    Moralis.Object<Moralis.Attributes>[] | null
  >(null);

  const [modalContent, setModalContent] = useState<ModalType>('wallet');

  const [dietInfoError, setDietInfoError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [infoErrors, setInfoErrors] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);
  const [otherPlatformError, setOtherPlatformError] = useState<boolean>(false);
  const [registerPage, setRegisterPage] = useState<number>(0);
  const [registerForm, setRegisterForm] = useState<FormI>({
    email: '',
    first_name: '',
    last_name: '',
    job_title: '',
    company: '',
    primary_channel: 'Blog',
    other_platform: '',
    dietary_options: {
      None: false,
      'Dairy Free': false,
      Kosher: false,
      'No Red Meat': false,
      'Nut Allergy': false,
      'Shellfish Allergy': false,
      Vegan: false,
      Vegetarian: false,
      Other: false,
    },
    diet_info: '',
    ada: '',
  });

  const [modalActive, setModalActive] = useState<boolean>(false);
  const [notificationModalActive, setNotificationModalActive] =
    useState<boolean>(false);
  const [receipt, setReceipt] = useState<string>('');
  const [mobileNavActive, setMobileNavActive] = useState<boolean>(false);
  const [ethEnabled, setEthEnabled] = useState<boolean>(false);
  const [isNETOwner, setIsNETOwner] = useState<boolean>(false);
  const [staticImageURI, setStaticImageURI] = useState<string>('');

  const breakpoint = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  const smallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const getModalHeader = () => {
    if (modalContent === 'wallet') {
      return 'Sign in with your wallet';
    } else if (modalContent === 'register') {
      if (registerPage === -1) {
        return 'Registration Received';
      } else {
        return 'Registration';
      }
    } else if (modalContent === 'my_net') {
      return 'My NET';
    }
  };

  const handleCheckboxChange = (name: DietaryTypes, value: boolean) => {
    if (name === 'None') {
      setRegisterForm(prevState => ({
        ...prevState,
        dietary_options: {
          None: value,
          'Dairy Free': false,
          Kosher: false,
          'No Red Meat': false,
          'Nut Allergy': false,
          'Shellfish Allergy': false,
          Vegan: false,
          Vegetarian: false,
          Other: false,
        },
      }));
    } else {
      setRegisterForm(prevState => ({
        ...prevState,
        dietary_options: { ...prevState.dietary_options, [name]: value },
      }));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRegisterForm(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMenuChange = (e: SelectChangeEvent<FormI['primary_channel']>) => {
    const { name, value } = e.target;

    value !== 'Other' &&
      setRegisterForm(prevState => ({ ...prevState, other_platform: '' }));

    setRegisterForm(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    let eth = window.ethereum;
    if (eth !== undefined) {
      setEthEnabled(true);
    } else {
      setEthEnabled(false);
    }

    if (breakpoint) setMobileNavActive(false);
  }, [breakpoint]);

  useEffect(() => {
    const waitForImageQuery = async (token_id: string) => {
      const image_uri = await ImageQuery(token_id);
      if (image_uri.length !== 0) {
        setStaticImageURI(image_uri[0].attributes.image);
        setIsNETOwner(true);
      }
    };

    const waitForUserBalance = async (
      user: Moralis.User<Moralis.Attributes>
    ) => {
      const user_balance = await GetUserBalance(user);
      waitForImageQuery(user_balance);
    };

    if (!isAuthenticated) {
      setIsNETOwner(false);
    }

    if (user) {
      waitForUserBalance(user);
    }
  }, [isAuthenticated]);

  // Update isRegistered to determine what modal shows up after the
  // user presses the "Register" button
  useEffect(() => {
    const waitForRegistrationQuery = async (
      user: Moralis.User<Moralis.Attributes>
    ) => {
      const registration_data = await RegistrationQuery(user);
      if (registration_data) {
        if (registration_data.length !== 0) {
          setRegistrationData(registration_data);
          setIsRegistered(true);
          setRegisterPage(-1);
          setSuccessfulRegister(false); // Reset this flag if the user decides to re-register
        }
      }
    };

    if (user) {
      waitForRegistrationQuery(user);
    }
  }, [isInitialized, successfulRegister]);

  const classes = useStyles();
  return (
    <>
      <div className={classes.landingPageContainer}>
        <MobileNav active={mobileNavActive}>
          {isNETOwner && isAuthenticated && (
            <MobileNavButton
              onClick={() => {
                setMobileNavActive(false);
                setModalContent('my_net');
                setModalActive(!modalActive);
              }}
            >
              My NET
            </MobileNavButton>
          )}
          {isAuthenticated && isNETOwner && (
            <MobileNavButton
              onClick={() => {
                setMobileNavActive(false);
                setModalContent('register');
                setModalActive(!modalActive);
              }}
            >
              Register
            </MobileNavButton>
          )}
          <MobileNavButton
            onClick={() => {
              setModalContent('wallet');
              setModalActive(!modalActive);
              setMobileNavActive(false);
            }}
          >
            {isAuthenticated
              ? user
                ? `Connected ${user.get('ethAddress').slice(0, 4)}...${user
                    .get('ethAddress')
                    .slice(
                      user.get('ethAddress').length - 5,
                      user.get('ethAddress').length - 1
                    )}`
                : 'Connect Wallet'
              : 'Connect Wallet'}
          </MobileNavButton>
        </MobileNav>
        {user &&
          localStorage.getItem(`${user.get('ethAddress')}-purchaseFlag`) ===
            'true' &&
          !isNETOwner && (
            <ModalNotification
              active={notificationModalActive}
              setActive={setNotificationModalActive}
              receipt={receipt}
            ></ModalNotification>
          )}
        <Modal
          active={modalActive}
          setActive={setModalActive}
          header={getModalHeader()}
          description={
            modalContent === 'wallet'
              ? 'Sign in with one of the available wallet providers or create a new wallet.'
              : ''
          }
          showImage={modalContent === 'my_net'}
          image={
            <>
              <div
                style={{
                  backgroundImage: `url(${staticImageURI})`,
                }}
                className={classes.displayNET}
              ></div>
            </>
          }
        >
          {modalContent === 'wallet' && (
            <>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
              >
                <ModalButton
                  highlighted
                  icon={metamask}
                  onClick={async () => {
                    if (ethEnabled) {
                      try {
                        await authenticate();
                      } catch (err) {}
                    } else {
                      alert(
                        'MetaMask not enabled on this browser\nhttps://metamask.io/download'
                      );
                    }
                  }}
                >
                  {smallScreen ? 'MetaMask' : 'Sign in with MetaMask'}
                </ModalButton>
                <ModalButton
                  icon={wallet_connect}
                  onClick={async () => {
                    try {
                      await authenticate({ provider: 'walletconnect' });
                    } catch (err) {}
                  }}
                >
                  {smallScreen ? 'WalletConnect' : 'Sign in with WalletConnect'}
                </ModalButton>
              </div>
            </>
          )}
          {modalContent === 'register' && registerPage === -1 && (
            <>
              <p className={classes.registrationParagraph}>
                You have successfully registered for the event
                <br />
                <br />
                You will receive an email confirmation within 48 hours. For
                questions, please contact{' '}
                {
                  <TextButton
                    isdark="true"
                    isunderlined="true"
                    size={'small'}
                    onClick={() => {
                      const mailto: string =
                        'mailto:info@cex.events?subject=CEX Never Ending Ticket subject&body=Question from NET owner:';
                      window.location.href = mailto;
                    }}
                  >
                    info@cex.events
                  </TextButton>
                }
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: 20,
                  gap: 20,
                }}
              >
                <p className={classes.registrationParagraph}>
                  Email:{' '}
                  <strong>
                    {registrationData
                      ? registrationData[0].attributes.email
                      : ''}
                  </strong>
                </p>
                <p className={classes.registrationParagraph}>
                  First Name:{' '}
                  <strong>
                    {registrationData
                      ? registrationData[0].attributes.first_name
                      : ''}
                  </strong>
                </p>
                <p className={classes.registrationParagraph}>
                  Last Name:{' '}
                  <strong>
                    {registrationData
                      ? registrationData[0].attributes.last_name
                      : ''}
                  </strong>
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: 20,
                }}
              >
                <SolidButton
                  onClick={() => {
                    setRegisterPage(0);
                  }}
                >
                  Update
                </SolidButton>
              </div>
            </>
          )}
          {modalContent === 'register' && registerPage === 0 && (
            <>
              <p className={classes.registrationParagraph}>
                As a CEX Never Ending Ticket owner, you are entitled to free
                registration to the Creator Economy Expo for as long as you hold
                the NFT. Please click "NEXT" to register for the upcoming CEX
                event.
              </p>

              <h1 className={classes.registrationHeader}>Event Details</h1>
              <p className={classes.registrationParagraph}>
                CEX - The Creator Economy Expo
                <br /> May 2 - 4, 2022
                <br />
                Arizona Grand Resort, Phoenix
              </p>
              <div className={classes.buttonContainer}>
                {isRegistered && (
                  <SolidButton
                    size={smallScreen ? 'medium' : 'large'}
                    onClick={() => {
                      setRegisterPage(-1);
                    }}
                  >
                    Back
                  </SolidButton>
                )}
                <SolidButton
                  size={smallScreen ? 'medium' : 'large'}
                  onClick={() => {
                    setRegisterPage(1);
                  }}
                >
                  Next
                </SolidButton>
              </div>
            </>
          )}
          {modalContent === 'register' && registerPage === 1 && (
            <>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
              >
                <OutlinedField
                  label="Email"
                  type="text"
                  name="email"
                  required
                  value={registerForm.email}
                  onChange={handleInputChange}
                  error={emailError}
                />
                <OutlinedField
                  label="First Name"
                  type="text"
                  name="first_name"
                  required
                  value={registerForm.first_name}
                  onChange={handleInputChange}
                  error={infoErrors[0]}
                />
                <OutlinedField
                  label="Last Name"
                  type="text"
                  name="last_name"
                  required
                  value={registerForm.last_name}
                  onChange={handleInputChange}
                  error={infoErrors[1]}
                />
                <OutlinedField
                  label="Job Title"
                  type="text"
                  name="job_title"
                  required
                  value={registerForm.job_title}
                  onChange={handleInputChange}
                  error={infoErrors[2]}
                />
                <OutlinedField
                  label="Company"
                  type="text"
                  name="company"
                  required
                  value={registerForm.company}
                  onChange={handleInputChange}
                  error={infoErrors[3]}
                />
              </div>
              <div className={classes.buttonContainer}>
                <SolidButton
                  size={smallScreen ? 'medium' : 'large'}
                  onClick={() => {
                    setRegisterPage(0);
                  }}
                >
                  Back
                </SolidButton>
                <SolidButton
                  size={smallScreen ? 'medium' : 'large'}
                  onClick={() => {
                    const validated_response = validateEmail(
                      registerForm.email
                    );

                    if (
                      validated_response === 'success' &&
                      registerForm.first_name !== '' &&
                      registerForm.last_name !== '' &&
                      registerForm.job_title !== '' &&
                      registerForm.company !== ''
                    ) {
                      setInfoErrors([false, false, false, false]);
                      setEmailError(false);
                      setRegisterPage(2);
                    } else {
                      if (validated_response !== 'success') {
                        setEmailError(true);
                      } else {
                        setEmailError(false);
                      }
                      setInfoErrors([
                        registerForm.first_name === '',
                        registerForm.last_name === '',
                        registerForm.job_title === '',
                        registerForm.company === '',
                      ]);
                    }
                  }}
                >
                  Next
                </SolidButton>
              </div>
            </>
          )}
          {modalContent === 'register' && registerPage === 2 && (
            <>
              <InputLabel id="primary-marketing-channel-label">
                My Core Audience is on
              </InputLabel>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 20,
                }}
              >
                <Select
                  labelId="primary-marketing-channel-label"
                  name="primary_channel"
                  value={registerForm.primary_channel}
                  onChange={handleMenuChange}
                  variant="outlined"
                >
                  <MenuItem value={'Blog'}>Blog</MenuItem>
                  <MenuItem value={'Discord'}>Discord</MenuItem>
                  <MenuItem value={'Email'}>Email</MenuItem>
                  <MenuItem value={'Podcast'}>Podcast</MenuItem>
                  <MenuItem value={'Facebook'}>Facebook</MenuItem>
                  <MenuItem value={'Instagram'}>Instagram</MenuItem>
                  <MenuItem value={'Medium'}>Medium</MenuItem>
                  <MenuItem value={'LinkedIn'}>LinkedIn</MenuItem>
                  <MenuItem value={'Pinterest'}>Pinterest</MenuItem>
                  <MenuItem value={'Snapchat'}>Snapchat</MenuItem>
                  <MenuItem value={'Telegram'}>Telegram</MenuItem>
                  <MenuItem value={'TikTok'}>TikTok</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
                {registerForm.primary_channel === 'Other' && (
                  <OutlinedField
                    label="Please specify"
                    type="text"
                    name="other_platform"
                    required
                    value={registerForm.other_platform}
                    onChange={handleInputChange}
                    error={otherPlatformError}
                  ></OutlinedField>
                )}
              </div>
              <div className={classes.buttonContainer}>
                <SolidButton
                  size={smallScreen ? 'medium' : 'large'}
                  onClick={() => {
                    setRegisterPage(1);
                  }}
                >
                  Back
                </SolidButton>
                <SolidButton
                  size={smallScreen ? 'medium' : 'large'}
                  onClick={() => {
                    if (registerForm.primary_channel !== 'Other') {
                      setOtherPlatformError(false);
                      setRegisterPage(3);
                    } else if (registerForm.other_platform !== '') {
                      setOtherPlatformError(false);
                      setRegisterPage(3);
                    } else {
                      setOtherPlatformError(true);
                    }
                  }}
                >
                  Next
                </SolidButton>
              </div>
            </>
          )}
          {modalContent === 'register' && registerPage === 3 && (
            <>
              <p className={classes.dietaryRestrictions}>
                Share your dietary restrictions
              </p>
              <FormControlLabel
                label="None"
                name="None"
                value={registerForm.dietary_options.None}
                onChange={(e, checked) => {
                  handleCheckboxChange('None', checked);
                }}
                control={<Checkbox />}
              />
              <FormControlLabel
                disabled={registerForm.dietary_options.None}
                label="Diary Free"
                checked={registerForm.dietary_options['Dairy Free']}
                value={registerForm.dietary_options['Dairy Free']}
                onChange={(e, checked) => {
                  handleCheckboxChange('Dairy Free', checked);
                }}
                control={<Checkbox />}
              />
              <FormControlLabel
                disabled={registerForm.dietary_options.None}
                label="Kosher"
                checked={registerForm.dietary_options.Kosher}
                value={registerForm.dietary_options.Kosher}
                onChange={(e, checked) => {
                  handleCheckboxChange('Kosher', checked);
                }}
                control={<Checkbox />}
              />
              <FormControlLabel
                disabled={registerForm.dietary_options.None}
                label="No Red Meat"
                checked={registerForm.dietary_options['No Red Meat']}
                value={registerForm.dietary_options['No Red Meat']}
                onChange={(e, checked) => {
                  handleCheckboxChange('No Red Meat', checked);
                }}
                control={<Checkbox />}
              />
              <FormControlLabel
                disabled={registerForm.dietary_options.None}
                label="Nut Allergy"
                checked={registerForm.dietary_options['Nut Allergy']}
                value={registerForm.dietary_options['Nut Allergy']}
                onChange={(e, checked) =>
                  handleCheckboxChange('Nut Allergy', checked)
                }
                control={<Checkbox />}
              />
              <FormControlLabel
                disabled={registerForm.dietary_options.None}
                label="Shellfish Allergy"
                checked={registerForm.dietary_options['Shellfish Allergy']}
                value={registerForm.dietary_options['Shellfish Allergy']}
                onChange={(e, checked) =>
                  handleCheckboxChange('Shellfish Allergy', checked)
                }
                control={<Checkbox />}
              />
              <FormControlLabel
                disabled={registerForm.dietary_options.None}
                label="Vegan"
                checked={registerForm.dietary_options.Vegan}
                value={registerForm.dietary_options.Vegan}
                onChange={(e, checked) =>
                  handleCheckboxChange('Vegan', checked)
                }
                control={<Checkbox />}
              />
              <FormControlLabel
                disabled={registerForm.dietary_options.None}
                label="Vegetarian"
                checked={registerForm.dietary_options.Vegetarian}
                value={registerForm.dietary_options.Vegetarian}
                onChange={(e, checked) =>
                  handleCheckboxChange('Vegetarian', checked)
                }
                control={<Checkbox />}
              />
              <FormControlLabel
                disabled={registerForm.dietary_options.None}
                label="Other"
                checked={registerForm.dietary_options.Other}
                value={registerForm.dietary_options.Other}
                onChange={(e, checked) =>
                  handleCheckboxChange('Other', checked)
                }
                control={<Checkbox />}
              />
              {(registerForm.dietary_options.Other ||
                registerForm.dietary_options['Nut Allergy']) && (
                <OutlinedField
                  label="Please specify"
                  value={registerForm.diet_info}
                  onChange={handleInputChange}
                  name="diet_info"
                  error={dietInfoError}
                />
              )}

              <div className={classes.buttonContainer}>
                <SolidButton
                  size={smallScreen ? 'medium' : 'large'}
                  onClick={() => {
                    setRegisterPage(2);
                  }}
                >
                  Back
                </SolidButton>
                <SolidButton
                  size={smallScreen ? 'medium' : 'large'}
                  disabled={
                    !registerForm.dietary_options.None &&
                    !registerForm.dietary_options['Dairy Free'] &&
                    !registerForm.dietary_options.Kosher &&
                    !registerForm.dietary_options['No Red Meat'] &&
                    !registerForm.dietary_options['Nut Allergy'] &&
                    !registerForm.dietary_options['Shellfish Allergy'] &&
                    !registerForm.dietary_options.Vegan &&
                    !registerForm.dietary_options.Vegetarian &&
                    !registerForm.dietary_options.Other
                  }
                  onClick={() => {
                    if (
                      (registerForm.dietary_options['Other'] ||
                        registerForm.dietary_options['Nut Allergy']) &&
                      registerForm.diet_info === ''
                    ) {
                      setDietInfoError(true);
                    } else {
                      setDietInfoError(false);
                      setRegisterPage(4);
                    }
                  }}
                >
                  Next
                </SolidButton>
              </div>
            </>
          )}
          {modalContent === 'register' && registerPage === 4 && (
            <>
              <p className={classes.registrationParagraph}>
                Note any physical restrictions we should be aware of, i.e.
                wheelchair access needed, hearing or vision impaired, etc.,
              </p>
              <OutlinedField
                label="Please specify"
                type="text"
                name="ada"
                value={registerForm.ada}
                onChange={handleInputChange}
                error={otherPlatformError}
              ></OutlinedField>
              <div className={classes.buttonContainer}>
                <SolidButton
                  onClick={() => {
                    setRegisterPage(3);
                  }}
                >
                  Back
                </SolidButton>
                <SolidButton
                  onClick={() => {
                    setRegisterPage(5);
                  }}
                >
                  Next
                </SolidButton>
              </div>
            </>
          )}
          {modalContent === 'register' && registerPage === 5 && (
            <>
              <p className={classes.registrationParagraph}>
                Click submit to complete your registration.
                <br />
                <br />
                Your registration for the event is only valid if you own the NFT
                at the time of the event. If you sell or transfer the NFT, your
                registration is automatically deleted.
              </p>
              <div className={classes.buttonContainer}>
                <SolidButton
                  onClick={() => {
                    setRegisterPage(4);
                  }}
                >
                  Back
                </SolidButton>
                <SolidButton
                  onClick={() => {
                    if (user) {
                      executeSave(
                        {
                          ...registerForm,
                          ethAddress: user.get('ethAddress'),
                        },
                        {
                          onSuccess: () => {
                            setSuccessfulRegister(true);
                            setRegisterPage(-1);
                          },
                        }
                      );
                    } else {
                      alert('Try reconnecting your wallet');
                    }
                  }}
                >
                  Submit
                </SolidButton>
              </div>
            </>
          )}
        </Modal>
        <Header>
          <Navbar
            breakpoint={breakpoint}
            active={mobileNavActive}
            setActive={setMobileNavActive}
          >
            {isNETOwner && isAuthenticated && (
              <TextButton
                onClick={() => {
                  setModalContent('my_net');
                  setModalActive(!modalActive);
                }}
              >
                My NET
              </TextButton>
            )}
            {isAuthenticated && isNETOwner && (
              <TextButton
                onClick={() => {
                  setModalContent('register');
                  setModalActive(!modalActive);
                }}
              >
                Register
              </TextButton>
            )}
            <SolidButton
              style={
                isAuthenticated ? (user ? { fontSize: '0.8rem' } : {}) : {}
              }
              onClick={() => {
                setModalContent('wallet');
                setModalActive(!modalActive);
              }}
            >
              {isAuthenticated
                ? user
                  ? `Connected ${user.get('ethAddress').slice(0, 4)}...${user
                      .get('ethAddress')
                      .slice(
                        user.get('ethAddress').length - 5,
                        user.get('ethAddress').length - 1
                      )}`
                  : 'Connect Wallet'
                : 'Connect Wallet'}
            </SolidButton>
          </Navbar>
        </Header>
        <Frame>
          <HeroFrame>
            <HeroText
              minted={
                mintingDataIsLoading && !mintingDataError
                  ? '-'
                  : mintingData.length
              }
              soldout={mintingData.length >= 100}
            >
              {user &&
              localStorage.getItem(`${user.get('ethAddress')}-purchaseFlag`) ===
                'true' &&
              !isNETOwner ? (
                <>
                  <SolidButton onClick={() => window.location.reload()}>
                    Refresh
                  </SolidButton>
                </>
              ) : (
                <SolidButton
                  disabled={mintingData.length >= 100}
                  onClick={async () => {
                    if (isAuthenticated && isNETOwner) {
                      myNETModal();
                    } else if (isAuthenticated && !isNETOwner) {
                      if (user && web3 && abi) {
                        const receipt_res = await MintNET(user, web3, abi);
                        setReceipt(receipt_res);
                        setNotificationModalActive(true);
                      }
                    } else {
                      setModalContent('wallet');
                      setModalActive(!modalActive);
                    }
                  }}
                >
                  {isAuthenticated
                    ? isNETOwner
                      ? 'My NET'
                      : mintingData.length >= 100
                      ? 'Sold Out'
                      : 'Mint my NET'
                    : 'Connect Wallet'}
                </SolidButton>
              )}

              <div style={{ height: 30 }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextButton
                  styletype="long"
                  onClick={() => {
                    window.open('https://youtube.com/', '_blank');
                  }}
                >
                  Need help? Watch our video walkthrough.
                </TextButton>
                <div style={{ height: 20 }} />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {/* <IconButton
                    src={discord}
                    onClick={() => {
                      window.open('https://discord.gg/rzHNBfTeEu', '_blank');
                    }}
                  ></IconButton> */}
                </div>
              </div>
            </HeroText>
            <HeroNFTs>
              <BoxImage src={NFT0_0} />
              <BoxImage src={NFT0_1} />
              <BoxImage src={NFT1_0} />
              <BoxImage src={NFT1_1} />
            </HeroNFTs>
            <br />
            <Ownership
              opensea={
                <>
                  <WideImageButton
                    src={opensea}
                    onClick={() => {
                      window.open(
                        'https://opensea.io/collection/cex-never-ending-tickets',
                        '_blank'
                      );
                    }}
                  ></WideImageButton>
                </>
              }
            ></Ownership>
            <Pricing>
              {user &&
              localStorage.getItem(`${user.get('ethAddress')}-purchaseFlag`) ===
                'true' &&
              !isNETOwner ? (
                <>
                  <SolidButton onClick={() => window.location.reload()}>
                    Refresh
                  </SolidButton>
                </>
              ) : (
                <SolidButton
                  disabled={mintingData.length >= 100}
                  onClick={async () => {
                    if (isAuthenticated && isNETOwner) {
                      myNETModal();
                    } else if (isAuthenticated && !isNETOwner) {
                      if (user && web3 && abi) {
                        const receipt_res = await MintNET(user, web3, abi);
                        setReceipt(receipt_res);
                        setNotificationModalActive(true);
                      }
                    } else {
                      setModalContent('wallet');
                      setModalActive(!modalActive);
                    }
                  }}
                >
                  {isAuthenticated
                    ? isNETOwner
                      ? 'My NET'
                      : mintingData.length >= 100
                      ? 'Sold Out'
                      : 'Mint my NET'
                    : 'Connect Wallet'}
                </SolidButton>
              )}
              <br />
              <TextButton
                styletype="long"
                isdark="true"
                onClick={() => {
                  window.open('https://youtube.com/', '_blank');
                }}
              >
                Need help? Watch our video walkthrough.
              </TextButton>
              <br />
              <br />
              <br />
            </Pricing>
            <FAQ
              CEXLink={
                <>
                  <TextButton
                    isdark="false"
                    isunderlined="true"
                    size={'small'}
                    onClick={() => {
                      window.open('https://cex.events/', '_blank');
                    }}
                  >
                    cex.events
                  </TextButton>
                </>
              }
              MetaMaskLink={
                <>
                  <TextButton
                    isdark="false"
                    isunderlined="true"
                    size={'small'}
                    onClick={() => {
                      window.open('https://metamask.io/', '_blank');
                    }}
                  >
                    metamask.io
                  </TextButton>
                </>
              }
              Register={
                <>
                  <TextButton
                    isdark="false"
                    isunderlined="true"
                    size={'small'}
                    onClick={
                      isAuthenticated
                        ? () => {
                            setModalContent('register');
                            setModalActive(!modalActive);
                          }
                        : () => {
                            setModalContent('wallet');
                            setModalActive(!modalActive);
                          }
                    }
                  >
                    register
                  </TextButton>
                </>
              }
            ></FAQ>
          </HeroFrame>
        </Frame>
      </div>
    </>
  );
};

export default LandingPage;
