import { createTheme } from '@mui/material';

import { color } from 'src/styles/color';
import { Theme } from '@mui/material';

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: color.secondary,
    },
    secondary: {
      main: color.secondary,
    },
  },
  breakpoints: {
    values: {
      xs: 400,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
