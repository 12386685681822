import React, { FC } from 'react';
import { CustomProps } from 'src/core';
import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';
import { color, fonts } from 'src/styles';

interface Props extends CustomProps {
  href?: string;
  styletype?: 'short' | 'long';
  onClick?: () => void;
  isdark?: 'true' | 'false';
  isunderlined?: 'true' | 'false';
  size?: 'standard' | 'small';
}

export const TextButton: FC<Props> = ({
  children,
  href,
  onClick,
  size = 'standard',
  isdark = 'false',
  isunderlined = 'false',
  styletype = 'short',
}) => {
  return (
    <>
      <StyledButton
        isdark={isdark}
        href={href}
        onClick={onClick}
        styletype={styletype}
        size={size}
        isunderlined={isunderlined}
      >
        {children}
      </StyledButton>
    </>
  );
};

const StyledButton = styled(Link, {
  shouldForwardProp: prop => prop !== 'size',
})<Props>(({ theme, styletype, isdark, size, isunderlined }) => ({
  ...(styletype === 'short' && {
    color: isdark === 'true' ? color.heading_text : color.background,
    fontFamily: fonts.rubik,
    fontWeight: 400,
    fontSize: size === 'standard' ? '1.4rem' : '1.2rem',
    textTransform: isunderlined === 'true' ? 'none' : 'uppercase',
    textDecoration: isunderlined === 'true' ? 'underlined' : 'none',

    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(80%)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  }),
  ...(styletype === 'long' && {
    color: isdark === 'true' ? color.heading_text : color.background,
    fontFamily: fonts.rubik,
    fontWeight: 400,
    fontSize: size === 'standard' ? '1.4rem' : '1.2rem',

    textDecoration: isunderlined === 'true' ? 'underlined' : 'none',

    transition: 'ease-in-out 0.3s',
    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(80%)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  }),
}));
