import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  boxContainer: {
    // width: '330px',
    // height: '330px',
    width: '16vw',
    height: '16vw',
    [theme.breakpoints.down('md')]: {
      width: '280px',
      height: '280px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      height: '200px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '180px',
      height: '180px',
    },
  },
  src: {
    width: '100%',
    height: '100%',
  },
}));
